import {
  FirebaseApp,
  FirebaseOptions,
  getApp,
  getApps,
  initializeApp,
} from "firebase/app";
import { ApiService } from "../../api/api.types";
import { Auth, getAuth, getIdToken } from "firebase/auth";
import {
  AnalysisFormData,
  CreateCompanyRequestProps,
  CreateUserRequestProps,
} from "@scaffcalc/backends-firebase";

class FirebaseFunctions implements ApiService {
  private app: FirebaseApp;
  private auth: Auth;
  private config: FirebaseOptions & { functionsLink: string };

  /** Initialize a FirebaseAuthentication object
   *
   * @param props
   */
  constructor(props: { config: FirebaseOptions & { functionsLink: string } }) {
    const { config } = props;

    this.app = !getApps().length ? initializeApp(config) : getApp();
    this.auth = getAuth(this.app);
    this.config = config;
  }

  public createCompany = async (company: CreateCompanyRequestProps) => {
    const { currentUser } = this.auth;
    if (!currentUser) return;

    const token = await getIdToken(currentUser);

    return fetch(`${this.config.functionsLink}/api/v1/superadmin/company`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(company),
    });
  };

  public generateEmailVerificationLink = async (email: string) => {
    const { currentUser } = this.auth;
    if (!currentUser) return;

    const token = await getIdToken(currentUser);

    return fetch(
      `${this.config.functionsLink}/api/v1/superadmin/emailVerificationLink/user`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "content-type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({ email }),
      }
    );
  };

  public fetchMapboxAPIKey = async (token: string) => {
    return fetch(
      `${this.config.functionsLink}/api/v1/secure/app/mapbox/token`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
  };

  public fetchHighlightProjectId = async () => {
    const { currentUser } = this.auth;

    if (!currentUser) return;
    const token = await getIdToken(currentUser);

    return fetch(
      `${this.config.functionsLink}/api/v1/secure/app/highlight/projectid`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      }
    );
  };

  public getAnalysis = async (data: AnalysisFormData) => {
    const { currentUser } = this.auth;
    if (!currentUser) return;

    const token = await getIdToken(currentUser);

    return fetch(`${this.config.functionsLink}/api/v1/secure/analyse`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        form: data,
      }),
    });
  };

  public getTrsCapacity = async () => {
    const { currentUser } = this.auth;
    if (!currentUser) return;

    const token = await getIdToken(currentUser);

    return fetch(`${this.config.functionsLink}/api/v1/secure/trs/capacity`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "content-type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
    });
  };

  public deactivateUser = async (id: string) => {
    const { currentUser } = this.auth;

    if (!currentUser) return;
    const token = await getIdToken(currentUser);

    return fetch(
      `${this.config.functionsLink}/api/v1/superadmin/deactivate/user`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "content-type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({ id }),
      }
    );
  };

  public activateUser = async (id: string) => {
    const { currentUser } = this.auth;
    if (!currentUser) return;

    const token = await getIdToken(currentUser);

    return fetch(
      `${this.config.functionsLink}/api/v1/superadmin/activate/user`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "content-type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({ id }),
      }
    );
  };

  public generateResetPasswordLink = async (email: string, uid: string) => {
    const { currentUser } = this.auth;
    if (!currentUser) return;

    const token = await getIdToken(currentUser);

    return fetch(
      `${this.config.functionsLink}/api/v1/superadmin/passwordResetLink/user`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "content-type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({ email, uid }),
      }
    );
  };

  public sendPasswordResetLink = async (email: string) => {
    return fetch(
      `${this.config.functionsLink}/api/v1/public/user/sendPasswordResetLink`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "content-type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify({ email }),
      }
    );
  }

  public createUser = async (
    user: Omit<CreateUserRequestProps, "creatorEmail">
  ) => {
    const { currentUser } = this.auth;
    if (!currentUser) return;

    const finalUser: CreateUserRequestProps = {
      ...user,
      creatorEmail: currentUser.email ?? "",
    };

    const token = await getIdToken(currentUser);

    return fetch(`${this.config.functionsLink}/api/v1/superadmin/user`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(finalUser),
    });
  };

  public getUsersData = async (ids: string[]) => {
    const { currentUser } = this.auth;
    if (!currentUser) return;

    const token = await getIdToken(currentUser);

    return fetch(`${this.config.functionsLink}/api/v1/superadmin/users`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ ids }),
    });
  };
}

export default FirebaseFunctions;
