import { FC, useEffect, useMemo, useRef } from "react";
import { Outlet } from "react-router-dom";
import {
  useLoadingActions,
  useUserEmail,
  useUserId,
  useCompanyName,
  useCompanyId,
} from "../../store";
import { FIREBASE_PROJECTID } from "@scaffcalc/packages-shared";
import { H } from "highlight.run";
import TrackerManagerProps from "./TrackerManager.types";
import { api } from "../../services";

const TrackerManager: FC<TrackerManagerProps> = () => {
  const highlight = useRef<boolean>(false);

  const { setLoading } = useLoadingActions();
  const userEmail = useUserEmail();
  const userId = useUserId();
  const companyName = useCompanyName();
  const companyId = useCompanyId();

  const isLocalHost = useMemo(
    () =>
      window.location.hostname === "localhost" ||
      window.location.hostname === "127.0.0.1" ||
      process.env.REACT_APP_FB_ENV === FIREBASE_PROJECTID.LOCAL,
    []
  );

  const isDevChannel = useMemo(() => {
    const { hostname } = window.location;
    const isDev = hostname.includes(FIREBASE_PROJECTID.SCAFFCALC_DEV);
    const endsWithWebApp = hostname.includes("web.app");

    return isDev && endsWithWebApp;
  }, []);

  useEffect(() => {
    if (isLocalHost || isDevChannel) return;

    if (userId && userEmail && !highlight.current) {
      setLoading(true);

      api
        .fetchHighlightProjectId()
        .then((res) => {
          if (res?.status !== 200)
            throw new Error("Failed to fetch H projectID - non 200 status");

          return res?.text();
        })
        .then((projectId) => {
          /** Only setup a tracker if we get a positive projectId back */
          if (projectId && userEmail && userId && companyName && companyId) {
            try {
              /** Init the H Session object */
              H.init(projectId, {
                serviceName: process.env.REACT_APP_FB_ENV || "no-env",
                tracingOrigins: true,
                networkRecording: {
                  enabled: true,
                  recordHeadersAndBody: true,
                  urlBlocklist: [],
                },
                enableCanvasRecording: true,
                samplingStrategy: {
                  canvas: 2,
                  canvasMaxSnapshotDimension: 460,
                  canvasClearWebGLBuffer: false,
                },
              });

              /** Identify the H session */
              H.identify(userEmail, {
                userId,
                companyName,
                companyId,
              });

              highlight.current = true;
              console.log("Success to init H object");
            } catch (error) {
              console.log("Failed to init H object");
              console.log(error);
              highlight.current = false;
            }
          } else {
            console.log("Failed to create H object");
            console.log(projectId, userEmail, userId, companyName, companyId);
            highlight.current = false;
          }
        })
        .catch((e) => {
          console.log("Failed to fetch H projectId from BE");
          console.log(e);
          console.log(H);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    return () => {
      /** Stop the highlight on unmount of TrackerManager */
      if (highlight.current) {
        highlight.current = false;
      }
    };
  }, [
    companyId,
    companyName,
    isDevChannel,
    isLocalHost,
    setLoading,
    userEmail,
    userId,
  ]);

  return <Outlet />;
};

export default TrackerManager;
