import {
  AnalysisFormData,
  CreateCompanyRequestProps,
  CreateUserRequestProps,
} from "@scaffcalc/backends-firebase";

export interface ApiService {
  createCompany: (
    company: CreateCompanyRequestProps
  ) => Promise<Response | undefined>;
  createUser: (
    company: Omit<CreateUserRequestProps, "creatorEmail">
  ) => Promise<Response | undefined>;
  generateResetPasswordLink: (
    email: string,
    uid: string
  ) => Promise<Response | undefined>;
  deactivateUser: (id: string) => Promise<Response | undefined>;
  activateUser: (id: string) => Promise<Response | undefined>;
  getAnalysis: (data: AnalysisFormData) => Promise<Response | undefined>;
  getTrsCapacity: () => Promise<Response | undefined>;
  fetchMapboxAPIKey: (token: string) => Promise<Response | undefined>;
  fetchHighlightProjectId: () => Promise<Response | undefined>;
  getUsersData: (ids: string[]) => Promise<Response | undefined>;
  sendPasswordResetLink: (email: string) => Promise<Response | undefined>;
}

export interface GetUsersAuthDataResponse {
  [id: string]: {
    isEmailVerified: boolean;
    hasSignedIn: boolean;
    lastSignInTime: string;
  };
}

export enum ApiServiceProvider {
  FIREBASE = "firebase",
}
